import NetworkError from "../../NetworkError";
import config from "../../config";
import store from "../../../store/configureStore"
import { AxiosInstance } from "axios";
import { isAxiosError } from "src/new-lib/_utils";
import { trackAxiosError } from "src/new-lib/_utils/analytics";
import type { GetPhoneValidationStatusResponse } from "./PhoneValidationAPIModel";

export type PhoneValidationApi = InstanceType<typeof PhoneValidationAPI>

export class PhoneValidationAPI {
  private _http: AxiosInstance
  private _endpoint: string

  constructor(http: AxiosInstance) {
    this._http = http;
    this._endpoint = `${config.base}/api/v2/account/validatephone/`;
  }

  async getPhoneValidationStatus(token: string) {
    const smsValidationFeatureIsEnabled = store.getState().featureManager.smsValidation;
    if (!smsValidationFeatureIsEnabled) return { isValidated: true };

    try {
      const res = await this._http.get<GetPhoneValidationStatusResponse>(`${this._endpoint}status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return res.data
    } catch (error) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        return error
      }

      throw error
    }
  }

  async sendOtpSms(token: string) {
    const res = await this._http.get(this._endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200 || res.status === 204) {
      return true;
    }

    return false;
  }

  async verifyOtp(token: string, otp: string) {
    const smsValidationFeatureIsEnabled = store.getState().featureManager.smsValidation;
    if (!smsValidationFeatureIsEnabled) return false;

    try {
      const { status } = await this._http.get(`${this._endpoint}vericode/${otp}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (status >= 200 && status < 300) return true

      else return false
    } catch (error) {
      if (error.response && error.response.status === 422) return false
      
      throw new NetworkError(error.response?.status, error.response?.data || error.message)
    }
  }
}
