import store from "src/store/configureStore";
import { RootState } from "src/store/Model";
import { paths } from "src/utils/urlPaths";

interface Link {
  display: string;
  to: string;
}

interface Section {
  header: string;
  links: Link[];
}

export const getFooterContent = () => {
  const state: RootState = store.getState();
  const footer = state.content.footer;
  return footer;
}

export const GetAboutUsSection = (footer: RootState['content']['footer']): Section => {
  const aboutUsContent = footer.data?.footerSection?.aboutUsSection ?? [];

  const aboutUsSection: Section = {
    header: 'Om Capio',
    links: aboutUsContent.map(link => ({
      display: link.text,
      to: link.url
    }))
  };

  return aboutUsSection;
}

export const GetSocialMediaSection = (footer: RootState['content']['footer']): Section => {
  const socialMediaContent = footer.data?.footerSection?.socialMediaSection ?? [];

  const socialMediaSection: Section = {
    header: 'Följ oss i sociala medier',
    links: socialMediaContent.map(link => ({
      display: link.text,
      to: link.url
    }))
  };

return socialMediaSection;
};

export const createFooterContent = (footer: RootState['content']['footer']) => {
  return [
    {
      header: 'Tjänster',
      links: [
        {
          display: 'Sök vård digitalt',
          to: paths['switchboard'],
          track: {
            name: 'seek_care_cta',
            category: 'home',
            action: 'to_home',
            pageContext: 'top_nav'
          }
        },
        {
          display: 'Lista dig hos Capio',
          to: paths['listing > select'],
          track: {
            name: 'listing_dropdown_link',
            category: 'listing',
            action: 'to_listing',
            pageContext: 'top_nav_dropdown'
          }
        },
        {
          display: 'Hitta en mottagning',
          to: paths['care units'],
          track: {
            name: 'topbar_menu_link',
            category: 'digital_care',
            action: 'to_digital_care',
            pageContext: 'top_nav_dropdown'
          }
        },
  
        {
          display: 'Registrera dig för Capio nyhetsbrev',
          to: paths['my profile']
        },
        {
          display: 'Uppdatera kontaktuppgifter',
          to: paths['my profile'],
          track: {
            category: 'my_profile',
            action: 'to_my_profile',
            pageContext: 'top_nav_dropdown'
          } 
        }
      ]
    },
    GetAboutUsSection(footer),
    {
      header: 'Kontakt',
      links: [
        {
          display: 'Vanliga frågor om digital vård',
          to: paths['faq'],
          track: {
            name: 'topbar_menu_link',
            category: 'awareness',
            action: 'to_faq',
            pageContext: 'top_nav_dropdown'
          }
        }
      ]
    },
    GetSocialMediaSection(footer),
  ]
}
