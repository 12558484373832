import type {
    FaqContent,
    FlowOnboardingStartContent,
    HomeContent,
    TopbarContent,
    ProfilePageContent,
    WebGlobalContent,
    TosContent
} from "src/api/services/ContentAPI"
import type { AxiosError } from "axios"
import { FooterContent } from "src/api/services/ContentAPI/ContentAPIModel"


export type ContentState = {
    faq: { isLoading: boolean, data?: FaqContent, error?: AxiosError }
    home: { isLoading: boolean, data?: HomeContent, error?: AxiosError },
    flowOnboardingStart: { isLoading: boolean, data?: FlowOnboardingStartContent, error?: AxiosError }
    topbar: { isLoading: boolean, data?: TopbarContent, error?: AxiosError }
    profile: { isLoading: boolean, data?: ProfilePageContent, error?: AxiosError }
    global: { isLoading: boolean, data?: WebGlobalContent, error?: AxiosError },
    tos: { isLoading: boolean, data?: TosContent, error?: AxiosError }
    footer: { isLoading: boolean, data?: FooterContent, error?: AxiosError }
}

export const initialState: ContentState = {
    faq: { isLoading: false },
    home: { isLoading: false },
    flowOnboardingStart: { isLoading: false },
    topbar: { isLoading: false },
    profile: { isLoading: false },
    global: { isLoading: false },
    tos: { isLoading: false },
    footer: { isLoading: false }
}
