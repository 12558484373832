import NetworkError from "../../NetworkError";
import config from "../../config";
import type {
  UpdateUserPayload,
  UpdateUserResponse,
  CreateUserPayload,
  GetUserInfoResponse,
  GetLatestTosResponse,
  GetTosStatusResponse
} from "./AccountAPIModel"
import { getAuthHeaders } from "src/api/shared";
import { AxiosInstance } from "axios";
import { isAxiosError } from "src/new-lib/_utils"
import { trackAxiosError } from "src/new-lib/_utils/analytics";
import store from "src/store/configureStore"
import { RootState } from "src/store/Model";

export type AccountApi = InstanceType<typeof AccountAPI>

const getUri = (base: string, endpoint = "") => {
  const state = store.getState() as RootState
  const useDark = state.app.isDarkLaunchEligible
  
  return `${base}${useDark ? "-dark" : ""}${endpoint}`
}


export class AccountAPI {
  private _http: AxiosInstance
  private _endpointV1: string
  private _endpointV2: string
  private _consentV2: string


  constructor(http: AxiosInstance) {
    this._http = http
    this._endpointV2 = `${config.base}/api/v2/account`
    this._endpointV1 =`${config.base}/api/v1/account`
    this._consentV2 = `${config.base}/api/v2/consents`
  }


  async deleteUserInfo(token: string) {
    const res = await this._http.delete(
      getUri(this._endpointV2),
      getAuthHeaders(token)
    );

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async getUserInfo(token: string) {
    const res = await this._http.get<GetUserInfoResponse>(
      getUri(this._endpointV2),
      getAuthHeaders(token)
    );

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async createUser(payload: CreateUserPayload, token: string) {
    const res = await this._http.post(
      getUri(this._endpointV1),
      payload,
      getAuthHeaders(token)
    );

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async updateUser(payload: UpdateUserPayload, token: string) {
    const res = await this._http.put<UpdateUserResponse>(
      getUri(this._endpointV2),
      payload,
      getAuthHeaders(token)
    );

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }


  async updateConsent(payload, token: string) {
    const res = await this._http.post(
      `${this._consentV2}/grants`,
      payload,
      getAuthHeaders(token)
    );

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  
  async getAllConsents(token: string) {
    const res = await this._http.get(
      this._consentV2,
      getAuthHeaders(token)
    );

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.data);
    }

    return res.data;
  }


  async getAllUserConsents(token: string) {
    const res = await this._http.get(
      `${this._consentV2}/grants`,
      getAuthHeaders(token)
    );

    if (res.status < 200 || res.status >= 300) {
      throw new NetworkError(res.status, res.data);
    }

    return res.data;
  }


  async updateSelectedCareUnit(token: string, payload: { id: string }) {
    const { status, data } = await this._http.patch(
      getUri(this._endpointV2, "/selectedcareunit"),
      payload,
      getAuthHeaders(token)
    );

    if (status < 200 || status >= 300) throw new NetworkError(status, data)
  }


  async getLatestTos(token: string) {
    try {
      const res = await this._http.get<GetLatestTosResponse>(
        getUri(this._endpointV2, "/tos"),
        getAuthHeaders(token)
      );

      return res.data
      
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        return error
      }
      throw error
    }
  }


  async getTosStatus(token: string) {
    try {
      const res = await this._http.get<GetTosStatusResponse>(
        getUri(this._endpointV2, "/tos/status"),
        getAuthHeaders(token)
      );

      return res.data
      
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        console.log(error)
        return error
      }
      throw error
    }
  }


  async acceptLatestTos(token: string) {
    try {
      await this._http.post(
        getUri(this._endpointV2, "/tos/agreement"),
        null,
        getAuthHeaders(token)
      );
      
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        trackAxiosError(error)
        return error
      }
      throw error
    }
  }


  async fetchUserIsRegistered(token: string) {
    try {
      await this._http.get<GetUserInfoResponse>(
        getUri(this._endpointV2),
        getAuthHeaders(token)
      )

      return true
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response.status === 404) return false
        throw error
      }

      throw error
    }
  }
}