import { useState } from "react";
import { connect } from "react-redux"
import { EmptyModal } from "src/new-lib/Modal";
import { Card } from "src/new-lib/Card";
import { Button } from "src/new-lib/Button";
import { createTryGetContentValueFn } from "src/api/services/ContentAPI/ContentAPI";
import { actions as tosActions } from "../../../store/reducers/terms-of-services";
import { Icon } from "src/new-lib/Icon";
import { options } from "src/new-lib/_styles";
import Markdown from "markdown-to-jsx";
import type { ContentState } from "src/store/reducers/content/contentState"
import type { TosState } from "src/store/reducers/terms-of-services/initialState";
import { Typography } from "src/new-lib/Typography";
import styles from "./styles.module.scss"
import classNames from "classnames";
import { useDeviceSize } from "src/new-lib/_utils/hooks";

type Props = {
  dispatchCancelAccount: () => Promise<void>;
  dispatchTosAgreement: () => Promise<void>;
  content: ContentState["tos"];
  latestTos: TosState["latestTos"]
};

const UpdatedTosAgreementModal = ({
  dispatchTosAgreement,
  dispatchCancelAccount,
  content,
  latestTos
}: Props) => {
  const [firstModalIsOpen, setFirstModalIsOpen] = useState(true);
  const [deleteAccountModalIsOpen, setDeleteAccountModalIsOpen] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [deleteAccountIsLoading, setDeleteAccountIsLoading] = useState(false)
  const tryGetValue = createTryGetContentValueFn(content);

  const { isMobile } = useDeviceSize();

  const handleAcceptTos = async () => {
    setIsAccepting(true);
    try {
      await dispatchTosAgreement();
      setFirstModalIsOpen(false);
    } catch (error) {
      console.error("Failed to accept ToS:", error);
    } finally {
      setIsAccepting(false);
    }
  };

  const handleOpenDeleteAccountModal = () => {
    setFirstModalIsOpen(false);
    setDeleteAccountModalIsOpen(true);
  };

  const handleCloseDeleteAccountModal = () => {
    setDeleteAccountModalIsOpen(false);
    setFirstModalIsOpen(true);
  };

  const contentContainerCss = classNames({ [styles["content-container"]]: true })
  const firstModalBtnContainerCss = classNames({ [styles["buttons-container-with-border"]]: true })
  const deleteModalBtnContainerCss = classNames({ [styles["buttons-container"]]: true })

  return (
    <>
      <EmptyModal disableBackgroundClick isOpen={firstModalIsOpen}>
        <Card size="md">
          <div className={contentContainerCss}>
            <Icon name="info" size="xxl" />
            <Typography tag="h2" align="center" margin={{ t: 32, b: isMobile ? 32 : 24 }}>
              {tryGetValue((c) => c.updatedTosModal?.title)}
            </Typography>
            <Typography tag="h4" align="center" margin={{ v: 12 }}>
              {tryGetValue((c) => c.updatedTosModal?.preamble)}
            </Typography>
            <Markdown options={options}>
              {latestTos.data?.content}
            </Markdown>
          </div>

          <div className={firstModalBtnContainerCss}>
            <Button expand onClick={handleAcceptTos} loading={isAccepting}>
              {tryGetValue((c) => c.updatedTosModal?.approveBtn)}
            </Button>
            <Button expand variant="secondary-danger" onClick={handleOpenDeleteAccountModal}>
              {tryGetValue((c) => c.updatedTosModal?.denyBtn)}
            </Button>
          </div>
        </Card>
      </EmptyModal>

      <EmptyModal disableBackgroundClick isOpen={deleteAccountModalIsOpen}>
        <Card size="md">
          <div className={contentContainerCss}>
            <Icon name="bin" size="xxl" />
            <Typography tag="h2" align="center" margin={{ t: 32 }}>
              {tryGetValue((c) => c.deleteAccountModal?.title)}
            </Typography>
            <Markdown options={options}>
              {tryGetValue((c) => c.deleteAccountModal?.text)}
            </Markdown>
          </div>

          <div className={deleteModalBtnContainerCss}>
            <Button expand variant="danger" loading={deleteAccountIsLoading} onClick={() => {
              setDeleteAccountIsLoading(true)
              dispatchCancelAccount()
            }}>
              {tryGetValue((c) => c.deleteAccountModal?.confirmBtn) || "Ta bort konto"}
            </Button>
            <Button expand variant="secondary-light" onClick={handleCloseDeleteAccountModal}>
              {tryGetValue((c) => c.deleteAccountModal?.abortBtn)}
            </Button>
          </div>
        </Card>
      </EmptyModal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  content: state.content.tos,
  latestTos: state.termsOfServices.latestTos
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchTosAgreement: () => dispatch(tosActions.fetchTosAgreement())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdatedTosAgreementModal)
