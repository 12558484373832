import React, { useEffect } from 'react'
import connect from 'react-redux/es/connect/connect'
import { getDisplayName } from '../../../store/selectors'
import { LoggedInLayoutScreen } from './LoggedInLayoutScreen'
import { RootState } from 'src/store/Model'
import { Dispatch, bindActionCreators } from 'redux'
import { actions } from 'src/store/reducers/content/actions'

type Props = {
  displayName: string
  children: React.ReactNode,
  content: RootState["content"]["topbar"],
  footer: RootState["content"]["footer"],
  fetchContent: () => void
}

const LayoutContainer = ({
  displayName,
  content,
  children,
  fetchContent,
  footer
}: Props) => {
  useEffect(() => { 
    fetchContent(); 
  }, [])
  return (
    <LoggedInLayoutScreen menuButtonText={displayName} content={content} footer={footer}>
      {children}
    </LoggedInLayoutScreen>
  )
}

const mapStateToProps = (state: RootState) => ({
  displayName: getDisplayName(state),
  content: state.content.topbar,
  footer: state.content.footer
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchContent: actions.fetchTopbar
}, dispatch)

export const LoggedInLayout = connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)

