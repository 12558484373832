import React from 'react'
import { connect } from 'react-redux'
import { Header, HeaderVariants } from 'src/new-lib/Header'
import { Footer } from 'src/new-lib/Footer'
import styles from './SwitchboardLayout.module.scss'
import { RootState } from 'src/store/Model'
import { createFooterContent } from '../FooterHelper'

const mapStateToProps = (state: RootState) => ({
  content: state.content.topbar,
  footer: state.content.footer
})

type SwitchboardLayoutProps = {
  displayName: string
  children: React.ReactNode
  variant: HeaderVariants
  content: RootState['content']['topbar'],
  footer: RootState['content']['footer']
} 

const SwitchboardLayoutContainer = (props: SwitchboardLayoutProps) =>{
    return <SwitchboardLayoutView {...props} />
}

export const SwitchboardLayoutView = ({
  displayName,
  children,
  variant,
  content,
  footer
}: SwitchboardLayoutProps) =>
  {
    const footerSections = createFooterContent(footer);
    return (
      <div className={styles['sboard-layout']}>
      <Header
        content={content}
        isLoggedIn
        menuButtonText={displayName}
        variant={variant}
      />
      {children}
      <Footer signedIn sections={footerSections} />
    </div>)
  } 

export const SwitchboardLayout = connect(
  mapStateToProps
)(SwitchboardLayoutContainer)

type ChatLayoutProps = {
  children: React.ReactNode
  content: RootState['content']['topbar']
  footer: RootState['content']['footer']
}

const ChatLayoutContainer = (props: ChatLayoutProps) => (
  <ChatLayoutView {...props} />
)

export const ChatLayoutView = ({ children, content, footer }) => {
  const footerSections = createFooterContent(footer)
  return (
    <div className={styles['sboard-layout']}>
      <Header content={content} isLoggedIn={true} variant="chat" />
      <div className={styles['child-container']}>{children}</div>
      <Footer signedIn sections={footerSections} />
    </div>
  )
}

export const ChatLayout = connect(mapStateToProps)(ChatLayoutContainer)
