import React from 'react'
import { Footer } from 'src/new-lib/Footer'
import { Header } from 'src/new-lib/Header'
import styles from './LoggedInLayoutScreen.module.scss'
import { RootState } from 'src/store/Model'
import { createFooterContent } from '../FooterHelper'

type Props = {
  menuButtonText: string
  children: React.ReactNode
  content: RootState['content']['topbar']
  footer: RootState['content']['footer']
}

export const LoggedInLayoutScreen = ({
  menuButtonText,
  content,
  footer,
  children
}: Props) => {
  
  const footerContent = createFooterContent(footer);

  return (
    <div className={styles.root}>
      <Header menuButtonText={menuButtonText} content={content} isLoggedIn />
      <div>{children}</div>
      <Footer signedIn sections={footerContent} />
    </div>
  )
}
