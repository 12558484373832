import { Component } from 'react'
import { thunks as featureThunks } from './store/reducers/featureManager'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { PrivateRoute } from './lib'
import {
  RegistrationRoutes,
  ProfileRoutes,
  FaqRoutes,
  ListingRoutes,
  FastListingRoutes,
  HealthcareUnitsRoutes,
  SignCallbackPageBeta,
  SwitchboardPage,
  FlowPage,
  HomePage
} from './pages'
import config from './api/config'
import { actions as appActions } from './store/reducers/app'
import { actions as contentActions } from './store/reducers/content'
import { actions as tosActions } from 'src/store/reducers/terms-of-services'
import { actions as accountActions } from 'src/store/reducers/accounts'
import { paths } from './utils/urlPaths'
import { ErrorPage } from './pages/ErrorPage/ErrorPage'
import { ScrollToTop } from './lib/ScrollToTop'
import { LoadingScreen } from './new-lib/LoadingScreen/LoadingScreen'
import { LogoutPage } from './pages/Logout'
import { NewsletterUnsubscribePage } from './pages/Newsletter'
import { LoginPage, CallbackPage } from './pages/Login'
import { VerifyCodePage } from './pages/PhoneValidation/VerifyCode'
import { CookiebotProxyPage } from './pages/CookiebotProxy'
import { ErrorBoundary } from 'react-error-boundary'
import { trackGlobalError } from './new-lib/_utils/analytics'
import { ValidatePhoneOnBlockedPathsModal } from './new-lib/_features/OtpPhoneValidationModal'
import UpdateTosAgreement from './pages/UpdatedTosAgreement/UpdateTosAgreement'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contactInfoLoaded: false,
      centersLoaded: false,
      centersRegions: false,
      loaded: false
    }
  }

  componentDidMount() {
    this.props
      .dispatch(featureThunks.tryFetchFeatureConfig())
      .then(this.handleFeatureFlagsLoaded())
  }

  handleFeatureFlagsLoaded = async () => {
    const dispatch = this.props.dispatch

    if (this.props.userLoggedIn) {
      await dispatch(appActions.fetchShouldUseServices2())
    }

    if (this.props.shouldUseService2) {
      const isRegistered = await dispatch(accountActions.isUserRegistered())

      if (isRegistered) {
        await dispatch(tosActions.fetchLatestTos())
        await dispatch(tosActions.fetchTosStatus())
      }
    }

    await dispatch(appActions.fetchMinimumUserState())
    this.setState({ loaded: true })

    dispatch(featureThunks.tryInitializeReloadInterval())
    dispatch(contentActions.fetchGlobal())
    dispatch(contentActions.fetchTopbar())
    dispatch(contentActions.fetchFooter())
    dispatch(contentActions.fetchTos())
  }

  renderApp = (userLoggedIn, userIsRegistered) => {
    console.log('Capio Online App')
    return (
      <div style={{ flex: 1, display: 'flex' }}>
        <Switch>
          <PrivateRoute
            search={this.props.location}
            isRegistered={userIsRegistered}
            isAuthenticated={userLoggedIn}
            path={paths['home']}
            errorOccured={this.props.errorOccured}
            component={HomePage}
          />
          {/* TODO: Try remove the duplication of Routes pointing to LoginPage */}
          <Route path={paths['index']} exact component={LoginPage} />
          <Route path="/noapp" exact component={LoginPage} />
          {/* TODO: Duplicate Route of LoginPage */}
          <Route path={paths['login']} render={() => <LoginPage />} />
          <PrivateRoute
            path={paths['flow > capio go']}
            exact
            component={FlowPage}
            isAuthenticated={userLoggedIn}
            unauthorizedPath={paths['login']}
            isRegistered={userIsRegistered}
          />
          <Route path={paths['callback']} component={CallbackPage} />
          <PrivateRoute
            path={'/registration'}
            component={RegistrationRoutes}
            isAuthenticated={userLoggedIn}
            unauthorizedPath={paths['login']}
          />
          <Route
            path={paths['login > callback']}
            component={SignCallbackPageBeta}
          />
          <Route path={paths['logout confirmation']} component={LogoutPage} />
          <Route
            path={paths['logout']}
            render={() => {
              const postLogoutURIRedirect = encodeURI(
                `${config.redirectBase}/logout`
              )

              const idToken = localStorage.getItem('idToken')
              const endSessionURI = `${config.baseAuth}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${postLogoutURIRedirect}`
              localStorage.clear()

              window.location.href = endSessionURI
              return <></>
            }}
          />
          <Route
            path={paths['unsubscribe (type, token)']}
            component={NewsletterUnsubscribePage}
          />
          <Route
            path={paths['cookiebot proxy']}
            component={CookiebotProxyPage}
          />
          <PrivateRoute
            search={this.props.location}
            isAuthenticated={userLoggedIn}
            path="/listing"
            component={ListingRoutes}
          />
          <PrivateRoute
            search={this.props.location}
            isAuthenticated={userLoggedIn}
            path={paths['switchboard']}
            isRegistered={userIsRegistered}
            component={() => <SwitchboardPage loadedApp={this.state.loaded} />}
          />
          <PrivateRoute
            search={this.props.location}
            isAuthenticated={userLoggedIn}
            path="/fastlisting"
            component={() => <FastListingRoutes />}
          />
          <PrivateRoute
            isAuthenticated={userLoggedIn}
            path={paths['faq']}
            component={() => <FaqRoutes />}
          />
          <PrivateRoute
            isAuthenticated={userLoggedIn}
            isRegistered={userIsRegistered}
            path={'/settings'}
            component={ProfileRoutes}
          />
          <PrivateRoute
            isAuthenticated={userLoggedIn}
            isRegistered={userIsRegistered}
            path={paths['care units']}
            component={() => <HealthcareUnitsRoutes />}
          />
          <Route path="/vericode/:code" component={VerifyCodePage} />
          <Route path={paths['error']} component={() => <ErrorPage />} />
        </Switch>
        <ValidatePhoneOnBlockedPathsModal />
        <UpdateTosAgreement />
      </div>
    )
  }

  render() {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={(error, errorInfo) => trackGlobalError(error, errorInfo)}
      >
        <Router>
          <>
            {this.state.loaded ? (
              <ScrollToTop>
                {this.renderApp(
                  this.props.userLoggedIn,
                  this.props.userIsRegistered
                )}
              </ScrollToTop>
            ) : (
              <LoadingScreen />
            )}
          </>
        </Router>
      </ErrorBoundary>
    )
  }
}

export default connect((state) => {
  return {
    userIsRegistered: state.app.userIsRegistered,
    userLoggedIn: state.app.userLoggedIn,
    shouldUseService2: state.app.shouldUseService2,
    errorOccured: state.app.errorOccured
  }
})(App)
