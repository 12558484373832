import React, { useState, useEffect } from "react";
import styles from "./ProfileView.module.scss";
import { Typography } from "src/new-lib/Typography";
import { Button } from "src/new-lib/Button";
import { TextField } from "src/new-lib/TextField";
import { InfoCheckbox } from "src/new-lib/Checkbox";
import { Modal } from "src/new-lib/Modal";
import { Accordion } from "src/new-lib/Accordion";
import { TermsOfServices } from "src/new-lib/TermsOfServices";
import { useDeviceSize } from "src/new-lib/_utils";
import Loader from "react-loader-spinner";
import scss from "src/new-lib/_styles/index.module.scss";
import { FixMeLater } from "src/new-lib/_types";
import SkeletonLoader from "src/new-lib/Skeleton/SkeletonLoader";
import { CheckboxSkeleton } from "src/new-lib/Skeleton/CheckboxSkeleton";
import { PhoneValidationSection } from "./PhoneValidationSection"
import { OtpPhoneValidationModal } from "src/new-lib/_features/OtpPhoneValidationModal";
import { ContentState } from "src/store/reducers/content/contentState";
import { createTryGetContentValueFn } from "src/api/services/ContentAPI";
import type { TosState } from "src/store/reducers/terms-of-services/initialState";
import type { GetLatestTosResponse } from "src/api/services/AccountAPI/AccountAPIModel";


type FieldProp = {
  value: string;
  errorText: string;
  onChange: (ev: React.FocusEvent) => void;
  disable: boolean;
};

type Props = {
  content: ContentState["profile"]
  name: string;
  phoneField: FieldProp;
  emailField: FieldProp;
  streetField: FieldProp;
  zipCodeField: FieldProp;
  cityField: FieldProp;
  consents: [
    {
      key: number;
      disable: boolean;
      checked: boolean;
      title: string;
      paragraphs: string[];
      onChange: (ev: React.ChangeEvent) => void;
    }
  ];
  onSaveChangesButtonClick: (ev: React.MouseEvent) => void;
  showHasSavedText: boolean;
  resetHasSavedText: () => void;
  onDeleteAccountClick: () => void;
  termsOfService: TosState;
  loading: boolean;
  hasValidatedPhone: boolean;
  onOtpSuccess: () => void
  phoneValidationFeatureIsEnabled: boolean
  hasUpdatedPhone: boolean
};

export const ProfileView = ({
  content,
  name,
  phoneField,
  emailField,
  streetField,
  zipCodeField,
  cityField,
  consents,
  onSaveChangesButtonClick,
  showHasSavedText,
  resetHasSavedText,
  onDeleteAccountClick,
  termsOfService,
  loading,
  hasValidatedPhone,
  onOtpSuccess,
  phoneValidationFeatureIsEnabled,
  hasUpdatedPhone
}: Props) => {  
  const { isMobile } = useDeviceSize();
  const tryGetValue = createTryGetContentValueFn(content)
  const getTosHeader = () => {
    const tos = termsOfService.latestTos.data as GetLatestTosResponse
    const defaultHeader = "Datahantering och villkor"
    
    try {
      if (typeof tos.title === "string")
        return tos.title
      else
        return defaultHeader
      
    } catch (error) {
      return defaultHeader
    }

  }

  const [deleteAccountModalIsOpen, setDeleteAccountModalIsOpen] = useState(false);
  const [phoneValidationModalIsOpen, setPhoneValidationModalIsOpen] = useState(false)

  const handleOpenDeleteAccountModal = () => setDeleteAccountModalIsOpen(true);
  const handleCloseDeleteAccountModal = () => setDeleteAccountModalIsOpen(false);

  const handleOpenPhoneValidationModal = () => setPhoneValidationModalIsOpen(true)
  const handleClosePhoneValidationModal = () => setPhoneValidationModalIsOpen(false)

  const [hasEditedProfile, setHasEditedProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneField = (ev: React.FocusEvent) => phoneField.onChange(ev);
  const handleEmailField = (ev: React.FocusEvent) => emailField.onChange(ev);
  const handleStreetField = (ev: React.FocusEvent) => streetField.onChange(ev);
  const handleZipCodeField = (ev: React.FocusEvent) => zipCodeField.onChange(ev);
  const handleCityField = (ev: React.FocusEvent) => cityField.onChange(ev);

  const handleChange = (ev: React.ChangeEvent) => {
    ev.preventDefault();
    setHasEditedProfile(true);
    resetHasSavedText();
  };

  const handleCheckChange = (ev: React.ChangeEvent, _onChange: React.ChangeEventHandler) => {
    ev.preventDefault();
    setHasEditedProfile(true);
    _onChange(ev);
    resetHasSavedText();
  };

  const handleSaveChanges = (ev: React.MouseEvent) => {
    ev.preventDefault();
    onSaveChangesButtonClick(ev);

    if (
      !phoneField.errorText &&
      !emailField.errorText &&
      !streetField.errorText &&
      !zipCodeField.errorText &&
      !cityField.errorText
    ) {
      setIsLoading(true);
      setHasEditedProfile(false);
    }
  };

  useEffect(() => {
    if (showHasSavedText) {
      setIsLoading(false);
      setHasEditedProfile(false);

      if (hasUpdatedPhone) {
        setPhoneValidationModalIsOpen(true)
      }
    }
  }, [showHasSavedText]);

  const renderTextFields = () => {
    return (
      <>
        {name && <TextField value={name} title="Namn" isDisabled />}
        <TextField
          value={phoneField.value}
          title="Mobilnummer"
          errorMessage={phoneField.errorText}
          isError={!!phoneField.errorText}
          onBlur={handlePhoneField}
          onChange={handleChange}
        />
        <TextField
          value={emailField.value}
          title="E-post"
          errorMessage={emailField.errorText}
          isError={!!emailField.errorText}
          onBlur={handleEmailField}
          onChange={handleChange}
        />
        <TextField
          value={streetField.value}
          title="Gatuadress"
          errorMessage={streetField.errorText}
          isError={!!streetField.errorText}
          onBlur={handleStreetField}
          onChange={handleChange}
        />
        <TextField
          value={zipCodeField.value}
          title="Postnummer"
          errorMessage={zipCodeField.errorText}
          isError={!!zipCodeField.errorText}
          onBlur={handleZipCodeField}
          onChange={handleChange}
        />
        <TextField
          value={cityField.value}
          title="Postort"
          errorMessage={cityField.errorText}
          isError={!!cityField.errorText}
          onBlur={handleCityField}
          onChange={handleChange}
        />
      </>
    );
  };

  const renderTextFieldSkeletons = () => {
    const marginBottom = isMobile ? "32px" : "48px"

    return (
      <div style={{ marginTop: "24px" }}>
        <div style={{ marginBottom }}>
          <SkeletonLoader />
        </div>
        <div style={{ marginBottom, width: "204px" }}>
          <SkeletonLoader />
        </div>
        <div style={{ marginBottom }}>
          <SkeletonLoader />
        </div>
        <div style={{ marginBottom, width: "204px" }}>
          <SkeletonLoader />
        </div>
        <div style={{ marginBottom }}>
          <SkeletonLoader />
        </div>
        <div style={{ marginBottom, width: "204px" }}>
          <SkeletonLoader />
        </div>
      </div>
    );
  };

  const Consents = (): React.ReactNode[] =>
    consents.map((con, index) => {
      return (
        <>
          {loading || isLoading
            ? <CheckboxSkeleton />
            : <InfoCheckbox
                key={index}
                defaultChecked={con.checked}
                label={con.title}
                modalHeading={con.title}
                modalText={{ markdownContent: con.paragraphs.join() }}
                buttonCloseLabel="Stäng"
                modalSize="md"
                modalTextAlign="left"
                onChange={(ev) => handleCheckChange(ev, con.onChange)}
              />}
        </>
      );
    });

  const [selectedAccordion, setSelectedAccordion] = useState<string | null>(null);
  const handleClick = (id: string) =>
    setSelectedAccordion(selectedAccordion === id ? null : id);

  return (
    <>
      {phoneValidationFeatureIsEnabled &&
        <OtpPhoneValidationModal
          title={tryGetValue(c => c.phoneValidationSection.modalTitle)}
          infoText={tryGetValue(c => c.phoneValidationSection.modalInfoText)}
          buttonText={tryGetValue(c => c.phoneValidationSection.modalButtonText)}
          isOpen={phoneValidationModalIsOpen}
          onClose={handleClosePhoneValidationModal}
          onSuccess={() => {
            handleClosePhoneValidationModal()
            onOtpSuccess()
          }}
        />}

      <section className={styles["hero-header"]}>
        <div className={styles["hero-content"]}>
          <Typography
            tag="h1"
            align={isMobile ? "center" : null}
            margin={{ b: isMobile ? 16 : 24 }}>
            {tryGetValue(c => c.heroSection.title)}
          </Typography>
          <Typography tag="p" align={isMobile ? "center" : null} margin={{}}>
            {tryGetValue(c => c.heroSection.infoText)}
          </Typography>
        </div>
      </section>
      
      <div className={styles.body}>
        <div className={styles["content-container"]}>
          {phoneValidationFeatureIsEnabled && !hasValidatedPhone &&
            <PhoneValidationSection
              title={tryGetValue(c => c.phoneValidationSection.cardTitle)}
              infoText={tryGetValue(c => c.phoneValidationSection.cardInfoText)}
              buttonText={tryGetValue(c => c.phoneValidationSection.cardButtonText)}
              openPhoneValidationModalHandler={handleOpenPhoneValidationModal}
              isMobile={isMobile}
            />}

          <section className={styles["edit-information-card"]}>
            <div className={styles["contact-details-container"]}>
              <Typography tag="h2" margin={{ b: 8 }}>
                Kontaktuppgifter
              </Typography>
              {loading || isLoading
                ? renderTextFieldSkeletons()
                : renderTextFields()}
            </div>
            <div className={styles["consents-container"]}>
              <div className={styles.consents}>
                <Typography tag="h2" margin={{ b: isMobile ? 4 : 12 }}>
                  Samtycken
                </Typography>
                {Consents()}
              </div>
              <div className={styles.btn}>
                <Button
                  expand
                  variant="primary"
                  onMouseUp={handleSaveChanges}
                  disabled={!hasEditedProfile}
                >
                  Spara ändringar
                </Button>
                <div className={styles["has-saved-changes-text-container"]}>
                  {isLoading && !showHasSavedText && (
                    <Loader
                      type="Oval"
                      color={scss.primaryNavy}
                      width={25}
                      height={25}
                    />
                  )}
                  {showHasSavedText && (
                    <Typography tag="p" margin={{}}>
                      Dina ändringar har sparats
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className={styles["remove-account-card"]}>
            <Typography tag="h2" margin={{}}>
              {tryGetValue(c => c.deleteAccountSection.cardTitle)}
            </Typography>
            <Typography tag="p" margin={{ t: isMobile ? 16 : 24, b: 24 }}>
              {tryGetValue(c => c.deleteAccountSection.cardInfoText)}
            </Typography>
            <div className={styles.btn}>
              <Button
                expand
                variant="secondary-light"
                onClick={handleOpenDeleteAccountModal}>
                {tryGetValue(c => c.deleteAccountSection.cardButtonText)}
              </Button>
            </div>
          </section>

          <Accordion
            id="1"
            selectedAccordion={selectedAccordion}
            onClick={handleClick}
            expand
            slim
            headerContent={
              <Typography tag="h3" margin={{}}>
                {getTosHeader()}
              </Typography>
            }>
            <TermsOfServices
              termsOfService={termsOfService}
              overrides={{
                h2: { component: Typography, props: { tag: "h4" } },
                h3: { component: Typography, props: { tag: "p" } },
                p: { component: Typography, props: { tag: "p" } },
                ul: { props: { className: styles.ul + " " + Typography } },
                li: { props: { className: styles.li } },
              }}
            />
          </Accordion>
        </div>

        <Modal
          iconName="bin"
          title={tryGetValue(c => c.deleteAccountSection.modalTitle)}
          text={tryGetValue(c => c.deleteAccountSection.modalInfoText)}
          isOpen={deleteAccountModalIsOpen}
          closeButton={{
            variant: isMobile ? "secondary-light" : "text",
            text: "avbryt",
          }}
          handleClose={handleCloseDeleteAccountModal}>
          <Button
            loading={loading}
            disabled={loading}
            variant="danger"
            onClick={onDeleteAccountClick}>
            {tryGetValue(c => c.deleteAccountSection.modalButtonText)}
          </Button>
        </Modal>
      </div>
    </>
  );
};
