import { useEffect } from 'react'
import { connect } from 'react-redux'
import { actions as tosActions } from '../../store/reducers/terms-of-services'
import { actions as appActions } from '../../store/reducers/app'
import UpdatedTosModal from 'src/new-lib/_features/UpdatedTosModal/UpdatedTosModal'

const UpdatedTosAgreement = ({
  shouldUseService2,
  tosStatus,
  dispatchFetchTos,
  dispatchCancelAccount
}) => {
  useEffect(() => {
    dispatchFetchTos()
  }, [dispatchFetchTos])

  if (!shouldUseService2 || tosStatus?.data !== 'outdated') {
    return null
  }

  return <UpdatedTosModal dispatchCancelAccount={dispatchCancelAccount} />
}

const mapStateToProps = (state) => ({
  shouldUseService2: state.app.shouldUseService2,
  tosStatus: state.termsOfServices.userTosStatus
})

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchTos: () => dispatch(tosActions.fetchTosStatus()),
  dispatchCancelAccount: () =>
    dispatch(appActions.deleteUserInfo()).then(() => {
      setTimeout(() => {
        window.location.replace('/logout')
      }, 1000)
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatedTosAgreement)
