import jwtDecode from "jwt-decode";
import config from "../config";
import type { IdentityInformation } from "src/store/reducers/app/initialState";
import type { AxiosInstance } from "axios";
import { isAxiosError } from "src/new-lib/_utils"
import { trackAxiosError } from "src/new-lib/_utils/analytics";

type Service2EligibilityResponse = { userIsEligible: boolean }

export type ConfigrationApi = InstanceType<typeof ConfigrationAPI>

export class ConfigrationAPI {
    private _http: AxiosInstance
    private _baseUrl: string
    private _getAuthHeaders: (token: string) => { headers: { Authorization: `Bearer ${string}` }}
  
    constructor(http: AxiosInstance) {
        this._http = http;
        this._baseUrl = config.base;
        this._getAuthHeaders = (token: string) => ({ headers: { Authorization: `Bearer ${token}` }})
    }

    async isUserEligibleForDarkLaunch(token: string) {
        try {
            const identityInformation = jwtDecode(token) as IdentityInformation
            const res = await this._http.get<Service2EligibilityResponse>(
                `${this._baseUrl}/api/v1/configuration/services-2-eligibility?ssn=${identityInformation.personalNumber}`,
                this._getAuthHeaders(token)
            )
            return res.data
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
            }
            throw error
        }
    }
}