import NetworkError from "../NetworkError";
import config from "../config";
import qs from "qs";

export class AppAPI {
  constructor(http) {
    this._http = http;
    this._auth_endpoint = `${config.baseAuth}`;
    this._endpoint = `${config.base}/api/v1`;
  }

  async endSession(id_token) {
    const url = `${this._auth_endpoint}/connect/endsession`;
    const res = await this._http.get(url, {
      params: {
        id_token_hint: `${id_token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  urlencodeFormData = fd => {
    var s = "";
    function encode(s) {
      return encodeURIComponent(s).replace(/%20/g, "+");
    }
    for (var pair of fd.entries()) {
      if (typeof pair[1] == "string") {
        s += (s ? "&" : "") + encode(pair[0]) + "=" + encode(pair[1]);
      }
    }
    return s;
  };

  async createrAccessToken(code, redirect_uri) {
    const url = `${this._auth_endpoint}/connect/token`;
    let code_verifier = localStorage.getItem('code_verifier');

    const data = {
      'client_id': 'capioWeb',
      'grant_type': 'authorization_code',
      'code': code,
      'redirect_uri': redirect_uri,
      'code_verifier': code_verifier

    };
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url,
    };
    const res = await this._http(options);

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.data);
    }
    return res.data;
  }

  async refreshTokens() {
    try {
      const response = await fetch(`${config.baseAuth}/connect/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: "capioWeb",
          grant_type: "refresh_token",
          refresh_token: sessionStorage.getItem("refresh_token")
        }).toString(),
      });

      if (!response.ok) {
        throw new Error(`Token refresh failed: ${response.status} ${response.statusText}`);
      }

      return await response.json();

    } catch (error) {
      console.error("Failed to refresh token:", error);
      throw error;
    }
  }
}
