import config from '../../config';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import type {
    FaqContent,
    FaqResponse,
    FlowOnboardingStartContent,
    FlowOnboardingStartResponse,
    HomeContent,
    HomeResponse,
    TopbarContent,
    TopbarResponse,
    ProfilePageResponse,
    ProfilePageContent,
    WebGlobalResponse,
    WebGlobalContent,
    TosResponse,
    TosContent,
    StrapiFooterResponse,
    FooterContent

} from './ContentAPIModel';
import { trackAxiosError } from 'src/new-lib/_utils/analytics';
import { isAxiosError } from 'src/new-lib/_utils';

export type ContentApi = InstanceType<typeof CmsApi>

export class CmsApi {
    private _http: AxiosInstance;
    private _endpoint: string;

    constructor(http: AxiosInstance) {
        this._http = http;
        this._endpoint = `${config.cmsBaseUri}`;
    }

    async getFooterPageContent(): Promise<FooterContent | AxiosError> {
        try {
            const res = await this._http.get<StrapiFooterResponse>(`${this._endpoint}/web-footer?populate[footerSection][populate]=aboutUsSection&populate[footerSection][populate]=socialMediaSection`)
            return res.data.data.attributes
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getFaqPageContent(): Promise<FaqContent | AxiosError> {
        try {
            const res = await this._http.get<FaqResponse>(`${this._endpoint}/web-faq?populate[sections][populate][qna]=*`)
            return res.data.data.attributes
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getHomePageContent(): Promise<HomeContent | AxiosError> {
        try {
            const res = await this._http.get<HomeResponse>(`${this._endpoint}/web-home?populate[sectionOne][populate][ctaButton]=*&populate[sectionTwo][populate][ctaButton]=*&populate[sectionThree][populate][ctaButton]=*&populate[sectionFour][populate][cardsCollection]=*`)
            return res.data.data.attributes

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getFlowOnboardingStartPageContent(): Promise<FlowOnboardingStartContent | AxiosError> {

        try {
            const res = await this._http.get<FlowOnboardingStartResponse>(`${this._endpoint}/web-flow-onboarding-start?populate=*`)
            return res.data.data.attributes

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getTopbarContent(): Promise<TopbarContent | AxiosError> {
        try {
            const res = await this._http.get<TopbarResponse>(`${this._endpoint}/web-topbar?populate=*`)
            return res.data.data.attributes

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }


    async getProfilePageContent(): Promise<ProfilePageContent | AxiosError> {
        try {
            const res = await this._http.get<ProfilePageResponse>(`${this._endpoint}/web-profile?populate=*`)
            return res.data.data.attributes
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getGlobalContent(): Promise<WebGlobalContent | AxiosError>  {
        try {
            const res = await this._http.get<WebGlobalResponse>(`${this._endpoint}/web-global?populate=*`)
            return res.data.data.attributes
        } catch (error) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getTosContent(): Promise<TosContent | AxiosError> {
        try {
            const res = await this._http.get<TosResponse>(`${this._endpoint}/web-tos?populate=*`)
            return res.data.data.attributes
        } catch (error: unknown){
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }
}

type Content = {
    data?: Object
    isLoading: boolean
}

export const createTryGetContentValueFn = <T extends Content>(content: T) => (selector: (c: T["data"]) => string | undefined) => {
    if (!content.data || content.isLoading)
        return ""
    return selector(content.data)
}
