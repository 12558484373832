import React from 'react'
import styles from './Footer.module.scss'
import { useDeviceSize } from '../_utils'
import { Logo } from '../Logo/Logo'
import { isWebUrl } from '../../utils/functions'
import { Link } from 'react-router-dom'
import { Icon } from '../Icon'
import classNames from 'classnames'
import { Typography } from '../Typography'
import { analytics } from '../_utils/analytics'
import { RootState } from 'src/store/Model'
import { connect } from 'react-redux'
import { createFooterContent } from 'src/pages/_layouts/FooterHelper'

type SectionType = {
  header: string
  links: {
    display: string
    to: string
    track?: {
      name?: string
      category: string
      action: string
      pageContext?: string
    }
  }[]
}

type FooterProps = {
  signedIn: boolean;
  sections?: SectionType[];
  footer: RootState['content']['footer'];
};

export const FooterLayoutContainer = ({ signedIn, footer }: FooterProps) => {
  const { isDesktop } = useDeviceSize()
  const text =
    'Capios vision är att förbättra hälsan för människor varje dag. Vi vill göra skillnad för våra patienter genom att se, lyssna och finnas här – idag och genom hela livet. Capio erbjuder ett brett och högkvalitativt vårdutbud genom våra sjukhus, specialistkliniker och vårdcentraler i Sverige, Norge och Danmark. Våra patienter kan även få hjälp via våra digitala tjänster. Capio är sedan 2018 en del av Ramsay Santé - en ledande vårdgivare med 36 000 medarbetare i sex länder. Tillsammans förbättrar vi folkhälsan – en individ i taget!'
  
  const sections = createFooterContent(footer);

  return isDesktop ? (
    <Desktop text={text} signedIn={signedIn} sections={sections} />
  ) : (
    <Mobile text={text} signedIn={signedIn} sections={sections} />
  )
}

const RenderSections: React.FC<{ data: SectionType }> = ({
  data: { header, links: linkData }
}) => {
  return (
    <section>
      <Typography margin tag="h5">
        <p className={styles['header-desktop']}>{header}</p>
      </Typography>
      <nav className={styles['column-section']}>
        {linkData &&
          linkData.map((data, i) => {
            return (
              <SectionLink key={i.toString()} data={data} track={data.track} />
            )
          })}
      </nav>
    </section>
  )
}

const SectionLink = ({ data, track }) => {
  const { isDesktop } = useDeviceSize()
  if (isWebUrl(data.to)) {
    return isDesktop ? (
      <li className={styles.links}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          href={data.to}
          onClick={() => {
            analytics.track({ ...track })
          }}
        >
          <Icon
            margin={{ r: '16px' }}
            color="secondary"
            size="sm"
            name="share-link"
          />
          {data.display}
        </a>
      </li>
    ) : (
      <li className={styles.links}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          href={data.to}
          onClick={() => {
            analytics.track({ ...track })
          }}
        >
          {data.display}
        </a>
      </li>
    )
  }
  return (
    <Link
      className={styles.link}
      to={data.to}
      onClick={() => {
        analytics.track({ ...track })
      }}
    >
      <p className={styles.links}>{data.display}</p>
    </Link>
  )
}

const Desktop = ({ text, sections, signedIn }) => {
  const desktopClassNames = classNames({
    [styles.desktop]: true,
    [styles['desktop-logged-in']]: signedIn
  })

  return (
    <footer data-track="footer_desktop" className={desktopClassNames}>
      <div className={styles.content}>
        <div className={styles['column-desktop']}>
          <Logo size="lg" color="light" />
          <p className={styles['text-content']}>{text}</p>
        </div>
        {signedIn && (
          <div className={styles['section-row']}>
            <RenderSections data={sections[0]} />
            <RenderSections data={sections[1]} />
            <RenderSections data={sections[2]} />
            <RenderSections data={sections[3]} />
          </div>
        )}
      </div>
    </footer>
  )
}

const Mobile = ({ text, sections, signedIn }) => {
  return (
    <footer
      data-track="footer_mobile"
      className={styles['mobile-container-signed-in']}
    >
      <div className={styles['mobile-second-container']}>
        <Logo size="md" color="light" />
        {signedIn && (
          <div>
            <div className={styles['first-section']}>
              <RenderSections data={sections[0]} />
              <div>
                <RenderSections data={sections[1]} />
                <div className={styles['section-margin']}>
                  <RenderSections data={sections[2]} />
                </div>
              </div>
            </div>
            <div className={styles['section-margin']}>
              <RenderSections data={sections[3]} />
            </div>
          </div>
        )}
        <div className={styles['text-div']}>
          <p className={styles['text-content']}>{text}</p>
        </div>
      </div>
    </footer>
  )
}

const mapStateToProps = (state: RootState) => ({
  footer: state.content.footer
})

export const Footer = connect(mapStateToProps)(FooterLayoutContainer);
