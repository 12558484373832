import NetworkError from "../NetworkError";
import config from "../config";
import { AxiosInstance } from "axios";

export type UnsubscribeType = "email" | "sms";

export type UnsubscribeApi = InstanceType<typeof UnsubscribeAPI>

export class UnsubscribeAPI {
    private _http: AxiosInstance
    private _unsubscribeV2: string

    constructor(http: AxiosInstance) {
        this._http = http
        this._unsubscribeV2 = `${config.base}/api/v1/unsubscribe`
    }

    async unsubscribe(type : UnsubscribeType, unsubscribeToken : string, messageSourceId: string) {
        const res = await this._http.post(this._unsubscribeV2, {
          type,
          unsubscribeToken, 
          messageSourceId
        });
    
        if (!(res.status >= 200 && res.status <= 299)) {
          throw new NetworkError(res.status, res.data);
        }
      }
}