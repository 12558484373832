import type { TosLegacy } from "src/api/services/TermsOfServiceAPI"
import type { GetLatestTosResponse, GetTosStatusResponse, GetTosAgreementResponse } from "src/api/services/AccountAPI/AccountAPIModel"
import { AxiosError } from "axios"
import { initialState } from "./initialState"


type TosActions =
    { type: "fetch latest tos" }
  | { type: "fetch latest tos succeeded", payload: TosLegacy | GetLatestTosResponse }
  | { type: "fetch latest tos failed", payload: AxiosError }
  | { type: "fetch tos status" }
  | { type: "fetch tos status succeeded", payload: GetTosStatusResponse["status"] }
  | { type: "fetch tos status failed", payload: AxiosError }
  | { type: "fetch tos agreement" }
  | { type: "fetch tos agreement success" , payload: GetTosAgreementResponse }
  | { type: "fetch tos agreement failed" , payload: AxiosError }

export type TosDispatcher = (args: TosActions) => void


export const reducer = (state = initialState, action: TosActions) => {
  switch (action.type) {
    case "fetch latest tos":
      return { ...state, latestTos: { isLoading: true } };
    case "fetch latest tos succeeded":
      return { ...state, latestTos: { data: action.payload, isLoading: false } };
    case "fetch latest tos failed":
      return { ...state, latestTos: { error: action.payload, isLoading: false } };
    case "fetch tos status":
      return { ...state, userTosStatus: { isLoading: true } };
    case "fetch tos status succeeded":
      return { ...state, userTosStatus: { data: action.payload, isLoading: false } };
    case "fetch tos status failed":
      return { ...state, userTosStatus: { error: action.payload, isLoading: false } };

    default:
      return state;
  }
};
