import React from "react"
import scss from "./Card.module.scss"
import classNames from "classnames"
import { type PaddingToCssProp, paddingToCssProp } from "../_utils"


type TagProps = {
  className: string
  children: React.ReactNode
  style: {
    padding: string
  }
}

const renderTag = {
  div: (props: TagProps) => <div {...props}>{props.children}</div>,
  section: (props: TagProps) => <section {...props}>{props.children}</section>,
  article: (props: TagProps) => <article {...props}>{props.children}</article>,
  aside: (props: TagProps) => <aside {...props}>{props.children}</aside>,
}

type CardProps = {
  tag?: "div" | "section" | "article" | "aside"
  size?: "sm" | "md" | "lg"
  children: React.ReactNode
  padding?: PaddingToCssProp
}

export const Card = ({
  tag = "div",
  size = "sm",
  children,
  padding = {}
}: CardProps) => {
  const cardClasses = classNames({
    [scss["card-base"]]: true,
    [scss[`card-${size}`]]: true
  })
  
  return renderTag[tag]({
    className: cardClasses,
    children,
    style: {
      padding: paddingToCssProp(padding)
    }
  })
}
