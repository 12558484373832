import {
  MY_INFO_CHANGE,
  MY_INFO_CHANGE_SPAR,
  MY_INFO_CHANGE_ALL,
  MY_CHILD_INFO_CHANGE,
  SELECT_CENTER,
  SYNC_SELECTED_CONSENTS,
  TOGGLE_CONSENT_CHECKBOX,
  ACCEPT_TOS,
  ACCEPT_TOS_RESPONSE,
  ACCEPT_TOS_RESPONSE_ERROR,
  UPDATE_CONTACT_INFORMATION,
  UPDATE_ASSOCIATED_CARE_GIVERS_INFO,
  UPDATE_CONTACT_INFORMATION_RESPONSE,
  UPDATE_CONTACT_INFORMATION_RESPONSE_ERROR,
  STORE_ALL_CONSENT_TYPES,
  STORE_SPAR_INFORMATION,
  STORE_SPAR_LOADING,
  SELECTE_SWITCHBOARD_REGION,
  SELECT_SWITCHBOARD_HCU,
  TOGGLE_CONSENT_TYPE_CHECKBOX,
  WAS_ROUTED_BY_SETTINGS
} from './action-types'
import { initialState } from './initialState'
import { getPersonId } from '../../../utils/personId'
import { USER_IS_NOT_REGISTERED, USER_IS_REGISTERED } from '../app/action-types'
import uniqBy from 'lodash/uniqBy'
import uuidv3 from 'uuid'

export const actions = {
  onMyInfoChange(name, value) {
    return {
      type: MY_INFO_CHANGE,
      payload: {
        name,
        value
      }
    }
  },
  onMyInfoChangeSpar(name, value) {
    return {
      type: MY_INFO_CHANGE_SPAR,
      payload: {
        name,
        value
      }
    }
  },
  onMyInfoChangeAll(value) {
    return {
      type: MY_INFO_CHANGE_ALL,
      payload: { ...value, mobileNumberVerification: '' }
    }
  },
  onMyChildInfoChange(name, value) {
    return {
      type: MY_CHILD_INFO_CHANGE,
      payload: {
        name,
        value
      }
    }
  },

  selectCenter(center) {
    return {
      type: SELECT_CENTER,
      payload: center
    }
  },

  selectSwitchBoardRegion(region) {
    return {
      type: SELECTE_SWITCHBOARD_REGION,
      payload: region
    }
  },

  selectSwitchBoardHCU(hcu) {
    return {
      type: SELECT_SWITCHBOARD_HCU,
      payload: hcu
    }
  },

  wasRoutedBySettings() {
    return {
      type: WAS_ROUTED_BY_SETTINGS
    }
  },

  syncAcceptedConsents(payload) {
    return {
      type: SYNC_SELECTED_CONSENTS,
      payload: payload
    }
  },

  storeSparLoading(payload) {
    return {
      type: STORE_SPAR_LOADING,
      payload: payload
    }
  },

  toggleConsentCheckbox(name, value) {
    return {
      type: TOGGLE_CONSENT_CHECKBOX,
      payload: {
        name,
        value
      }
    }
  },

  toggleConsentTypeCheckbox(id, checked) {
    return {
      type: TOGGLE_CONSENT_TYPE_CHECKBOX,
      payload: {
        id,
        checked
      }
    }
  },

  acceptTerms(cb) {
    return (dispatch, getState, api) => {
      dispatch({ type: ACCEPT_TOS })
      dispatch({ type: ACCEPT_TOS_RESPONSE })
      cb()
    }
  },

  getAllConsentTypes(initial) {
    return (dispatch, getState, api) => {
      const { app } = getState()

      return api.services.consent
        .getAllConsents(app.sessionToken)
        .then((payload) => {
          if (initial) {
            dispatch({ type: STORE_ALL_CONSENT_TYPES, payload })
          }
          return Promise.resolve(payload)
        })
        .catch((err) => {
          return Promise.reject()
        })
    }
  },

  getSparInformation() {
    return (dispatch, getState, api) => {
      const { app } = getState()
      return api.services.consent
        .getSparInformation(app.sessionToken)
        .then((payload) => {
          dispatch({ type: STORE_SPAR_INFORMATION, payload })
          return Promise.resolve(payload)
        })
        .catch((err) => {
          return Promise.reject()
        })
    }
  },
  fetchSparInfo() {
    return (dispatch, getState, api) => {
      const { app } = getState()
      api.services.consent
        .getSparInformation(app.sessionToken)
        .then((payload) => {
          dispatch(
            actions.onMyInfoChangeAll({
              streetAddress: payload.address,
              zipCode: payload.zipcode,
              postAddress: payload.city
            })
          )
        })
    }
  },

  getAllUserConsentTypes() {
    return (dispatch, getState, api) => {
      const { app } = getState()
      const personId = getPersonId(app.identityInformation.personalNumber)

      return api.services.consent
        .getAllUserConsents(app.sessionToken, personId)
        .then((payload) => {
          return Promise.resolve(payload)
        })
        .catch((err) => {
          //dispatch({ type: ACCEPT_TOS_RESPONSE_ERROR, payload: err });
          return Promise.reject()
        })
    }
  },

  saveAllUserConsentTypes() {
    return (dispatch, getState, api) => {
      const { app, consent } = getState()

      let filteredConsentTypes = consent.consentTypes.filter((item) => {
        if (item.isAccepted) {
          return item.consentTypeId
        } else {
          return false
        }
      })

      let acceptedConsentsIds = filteredConsentTypes.map((item) => {
        if (item.isAccepted) {
          return item.consentTypeId
        } else {
          return false
        }
      })

      return api.services.consent
        .saveUserConsents(acceptedConsentsIds, app.sessionToken)
        .then((payload) => {
          return Promise.resolve(payload)
        })
        .catch((err) => {
          //dispatch({ type: ACCEPT_TOS_RESPONSE_ERROR, payload: err });
          return Promise.reject()
        })
    }
  },

  createConsent() {
    return (dispatch, getState, api) => {
      const { app } = getState()
      const personId = getPersonId(app.identityInformation.personalNumber)

      return api.services.consent
        .getAllConsents(app.sessionToken, personId)
        .then((payload) => {
          return Promise.resolve(payload)
        })
        .catch((err) => {
          return Promise.reject()
        })
    }
  },

  createListingContract(visibleData, hash, hiddenDataBackend) {
    return (dispatch, getState, api) => {
      const { app } = getState()

      return api.services.listing
        .createListingContract(app.sessionToken, {
          visibleData: visibleData,
          hiddenData: hiddenDataBackend,
          hash: hash
        })
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }
  },

  createUser() {
    return (dispatch, getState, api) => {
      const contact = getState().consent.myInformation
      const { app, consent } = getState()

      let acceptedConsents = consent.consentTypes.filter(
        (item) => item.isAccepted === true
      )
      let acceptedConsentsIds = acceptedConsents.map((item) => {
        return item.consentTypeId
      })

      return api.services.consent
        .createUser(
          {
            swedishSocialSecurityNumber: {
              socialSecurityNumber: app.identityInformation.personalNumber
            },
            contactInfo: {
              emailAddress: contact.emailAddress,
              mobileNumber: contact.validatedMobileNumber,
              dayTimePhoneNumber: contact.mobileNumber,
              streetAddress: contact.streetAddress,
              postAddress: `${contact.postAddress}`,
              zipCode: contact.zipCode,
              countryNumber: contact.countryNumber
            },
            IdCollectionOfGivenConsents: acceptedConsentsIds,
            IdOfAcceptedServiceTerms: uuidv3()
          },
          app.sessionToken
        )
        .then(() => {
          dispatch({ type: USER_IS_REGISTERED })
          return Promise.resolve()
        })
        .catch((err) => {
          console.log(err)
          //cb(); // FIXME: Redirect to error page or display error.
          dispatch({ type: USER_IS_NOT_REGISTERED })
          return Promise.reject()
        })
    }
  },

  updateContactInformation() {
    return (dispatch, getState, api) => {
      dispatch({ type: UPDATE_CONTACT_INFORMATION })

      const contact = getState().consent.myInformation
      const { app } = getState()
      const personId = getPersonId(app.identityInformation.personalNumber)

      return api.services.consent
        .updateContactInformation(
          personId,
          {
            emailAddress: contact.emailAddress,
            mobileNumber: contact.validatedMobileNumber,
            countryNumber: contact.countryNumber,
            dayTimePhoneNumber: contact.dayTimePhoneNumber,
            streetAddress: contact.streetAddress,
            postAddress: contact.postAddress, //`${contact.postAddress} ${contact.streetAddress}`,
            zipCode: contact.zipCode
          },
          app.sessionToken
        )
        .then((data) => {
          dispatch({ type: UPDATE_CONTACT_INFORMATION_RESPONSE })
          return data
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_CONTACT_INFORMATION_RESPONSE_ERROR,
            payload: err
          })
          return Promise.reject()
        })
    }
  },

  updateAssociatedCareGivers() {
    return (dispatch, getState, api) => {
      const hcuId = getState().consent.switchBoard.selectedHealthCareUnitId
      const { app } = getState()
      const apiService = app.shouldUseService2 ? api.services.account : api.services.consent

      return apiService
        .updateSelectedCareUnit(app.sessionToken, { id: hcuId })
        .then(() => {
          dispatch({ type: UPDATE_ASSOCIATED_CARE_GIVERS_INFO, payload: hcuId })
          return Promise.resolve()
        })
        .catch((err) => {
          return Promise.reject()
        })
    }
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_INFO_CHANGE:
      return {
        ...state,
        myInformation: {
          ...state.myInformation,
          [action.payload.name]: action.payload.value
        }
      }
    case MY_INFO_CHANGE_SPAR:
      return {
        ...state,
        sparInformation: {
          ...state.sparInformation,
          [action.payload.name]: action.payload.value
        }
      }

    case MY_INFO_CHANGE_ALL:
      return {
        ...state,
        myInformation: {
          ...state.myInformation,
          ...action.payload
        }
      }

    case MY_CHILD_INFO_CHANGE:
      return {
        ...state,
        child: {
          ...state.child,
          [action.payload.name]: action.payload.value
        }
      }

    case STORE_SPAR_LOADING:
      return {
        ...state,
        sparLoading: action.payload
      }

    case STORE_SPAR_INFORMATION:
      return {
        ...state,
        sparLoading: true,
        sparInformation: {
          ...state.sparInformation,
          streetAddress: action.payload.address,
          zipCode: action.payload.zipcode,
          postAddress: action.payload.city,
          privacyMark: action.payload.privacymark
        }
      }

    case SELECT_CENTER:
      return {
        ...state,
        selectedCenter: action.payload
      }

    case SELECTE_SWITCHBOARD_REGION:
      return {
        ...state,
        switchBoard: {
          ...state.switchBoard,
          selectedRegion: action.payload
        }
      }

    case SELECT_SWITCHBOARD_HCU:
      return {
        ...state,
        switchBoard: {
          ...state.switchBoard,
          selectedHealthCareUnitId: action.payload
        }
      }

    case TOGGLE_CONSENT_CHECKBOX:
      return {
        ...state,
        isChecked: action.payload,
        consents: {
          ...state.consents,
          [action.payload.name]: action.payload.value
        }
      }

    case ACCEPT_TOS:
      return {
        ...state,
        loading: true
      }

    case WAS_ROUTED_BY_SETTINGS:
      return {
        ...state,
        wasRoutedBySettings: true
      }

    case STORE_ALL_CONSENT_TYPES:
      return {
        ...state,
        consentTypes: action.payload.map((item) => {
          return {
            ...item,
            isAccepted: false
          }
        })
      }

    case SYNC_SELECTED_CONSENTS:
      let accepted = action.payload.all.reduce((acc, item, _) => {
        action.payload.user.map((userItem) => {
          if (userItem.consentType.consentTypeId === item.consentTypeId) {
            acc.push({
              ...item,
              isAccepted: true
            })
          }
          return true
        })
        action.payload.user.map((userItem) => {
          if (userItem.consentType.consentTypeId !== item.consentTypeId) {
            acc.push({
              ...item,
              isAccepted: false
            })
          }
          return true
        })
        return acc
      }, [])
      if (action.payload.user.length === 0) {
        accepted = action.payload.all
      }
      let uniqueSet = uniqBy(accepted, 'consentTypeId')
      return {
        ...state,
        consentTypes: uniqueSet
      }

    case TOGGLE_CONSENT_TYPE_CHECKBOX:
      return {
        ...state,
        consentTypes: state.consentTypes.map((item) => {
          if (item.consentTypeId === action.payload.id)
            return {
              ...item,
              isAccepted: action.payload.checked
            }
          else {
            return {
              ...item
            }
          }
        })
      }

    case ACCEPT_TOS_RESPONSE:
      return {
        ...state,
        loading: false,
        acceptedServiceConditions: true
      }

    case UPDATE_CONTACT_INFORMATION:
      return {
        ...state,
        loading: true
      }

    case UPDATE_ASSOCIATED_CARE_GIVERS_INFO:
      return {
        ...state,
        myInformation: {
          ...state.myInformation,
          shouldSyncHealthCareManual: false,
          primaryHealthCareEntityId: action.payload
        }
      }

    case UPDATE_CONTACT_INFORMATION_RESPONSE:
      return {
        ...state,
        loading: false,
        didUpdateContactInformation: true
      }

    case UPDATE_CONTACT_INFORMATION_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        errors: [action.payload]
      }

    case ACCEPT_TOS_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        errors: [action.payload]
      }

    default:
      return state
  }
}
