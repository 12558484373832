import React, { useState } from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { marginToCssProp, isWebUrl } from "../_utils";
import { getButtonIconColors } from "src/new-lib/_utils";
import { Link } from "react-router-dom";
import { Icon } from "../Icon";
import Loader from "react-loader-spinner";
import { FixMeLater } from "src/new-lib/_types";
import { IconNames, IconSizes } from "src/new-lib/Icon";
import { To, Targets } from "src/new-lib/LinkTo";
import { analytics } from "../_utils/analytics";
import { createPath } from "src/utils/urlPaths";

/**
 * @description Button Component
 *
 * Add a value to the "to" prop to make the Button into a LinkedButton
 **/
export type ButtonTypes = "button" | "submit" | "reset";
export type ButtonVariants =
  | "primary"
  | "secondary-dark"
  | "secondary-light"
  | "text"
  | "danger"
  | "tertiary"
  | "secondary-danger";

interface Props {
  variant?: ButtonVariants;
  onClick?: React.MouseEventHandler;
  onMouseUp?: React.MouseEventHandler;
  children?: React.ReactNode;
  small?: boolean;
  disabled?: boolean;
  to?: To;
  targetTo?: Targets;
  expand?: boolean;
  margin?: FixMeLater;
  type?: ButtonTypes;
  form?: string;
  iconLeft?: IconNames;
  iconRight?: IconNames;
  iconSize?: IconSizes;
  childMargin?: FixMeLater;
  loading?: boolean;
  track?: FixMeLater;
}

export const Button = ({
  variant = "primary",
  onClick,
  onMouseUp,
  children,
  small,
  disabled,
  to,
  targetTo = "_self",
  expand,
  margin,
  type,
  form,
  iconLeft,
  iconRight,
  iconSize,
  childMargin,
  loading,
  track,
}: Props) => {
  const toPath = createPath(to);

  const root = classNames({
    [styles.primary]: variant === "primary",
    [styles["primary-disabled"]]: variant === "primary" && disabled,

    [styles.secondary]: variant.includes("secondary"),
    [styles["secondary-disabled"]]: variant.includes("secondary") && disabled,

    [styles.light]: variant === "secondary-light",
    [styles.dark]: variant === "secondary-dark",
    [styles["secondary-danger"]]: variant === "secondary-danger",

    [styles.tertiary]: variant === "tertiary",

    [styles.text]: variant === "text",
    [styles["text-disabled"]]: variant === "text" && disabled,

    [styles.danger]: variant === "danger",
    [styles["danger-disabled"]]: variant === "danger" && disabled,

    [styles["small-base"]]: small,
    [styles.expand]: expand,
    [styles["link-button-base"]]: toPath,

    [styles.loading]: loading,
  });

  const [
    defaultIconColor,
    hoverIconColor,
    disabledIconColor,
  ] = getButtonIconColors(variant);

  const [iconColor, setIconColor] = useState(
    disabled ? disabledIconColor : defaultIconColor
  );

  const handleMouseEnter = () => {
    if (iconLeft || iconRight) setIconColor(hoverIconColor);
  };
  const handleMouseLeave = () => {
    if (iconLeft || iconRight) setIconColor(defaultIconColor);
  };

  const calculateLeftIconMargins = () => {
    if (iconLeft === "share-link") return { b: 6 };
    if (iconLeft === "inbox") return { t: 0, r: 8 };
    return { b: 1 };
  };

  const ButtonContent = () => {
    return (
      <div className={styles["button-content"]}>
        {iconLeft && (
          <Icon
            margin={calculateLeftIconMargins()}
            name={iconLeft}
            color={iconColor}
            size={iconSize}
          />
        )}
        <div
          className={styles["button-text"]}
          style={{ margin: marginToCssProp(childMargin) }}
        >
          {(loading && (
            <Loader type="Oval" color="white" width={34} height={34} />
          )) ||
            children}
        </div>
        {iconRight && (
          <Icon
            name={iconRight}
            margin={iconRight === "share-link" ? { b: 6 } : { b: 1 }}
            color={iconColor}
            size={iconSize}
          />
        )}
      </div>
    );
  };

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      ev && ev.preventDefault();
      onClick(ev);
      track && analytics.track({ ...track });
    }
  };

  const handleMouseUp: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    if (onMouseUp) {
      ev && ev.preventDefault();
      onMouseUp(ev);
      track && analytics.track({ ...track });
    }
  };

  const Button = () => {
    return (
      <button
        className={root}
        onClick={handleClick}
        onMouseUp={handleMouseUp}
        style={{ margin: marginToCssProp(margin) }}
        type={type}
        form={form}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={loading}
      >
        <ButtonContent />
      </button>
    );
  };

  const LinkButton = () => {
    return isWebUrl(toPath) ? (
      <a
        className={root}
        href={toPath}
        target={targetTo}
        rel="noreferrer"
        style={{ margin: marginToCssProp(margin) }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => analytics.track({ ...track })}
      >
        <ButtonContent />
      </a>
    ) : (
      <Link
        // disabled={loading}
        className={root}
        to={toPath}
        target={targetTo}
        rel="noreferrer"
        style={{ margin: marginToCssProp(margin) }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => analytics.track({ ...track })}
      >
        <ButtonContent />
      </Link>
    );
  };

  return toPath ? <LinkButton /> : <Button />;
};
