import type { TosLegacy } from "src/api/services/TermsOfServiceAPI"
import type { GetLatestTosResponse, GetTosStatusResponse } from "src/api/services/AccountAPI/AccountAPIModel"
import type { AxiosError } from "axios"

export type TosState = {
  latestTos: {
    isLoading: boolean
    data?: TosLegacy | GetLatestTosResponse
    error?: AxiosError
  }
  userTosStatus: {
    isLoading: boolean
    data?: GetTosStatusResponse
    error?: AxiosError
  }
}

export const initialState: TosState = {
  latestTos: { isLoading: false },
  userTosStatus: { isLoading: false }
};
