import { AxiosError } from "axios";
import { FixMeLater } from "src/new-lib/_types/"
import { ButtonVariants, IconButtonVariants } from "src/new-lib/Button" // Types
import { IconColors, CareUnitIcons } from "src/new-lib/Icon" // Types


export const isWebUrl = (str: string): boolean => {
  const urlPattern = new RegExp("(^http[s]?:\/{2})|(^www)|(^\/{2})");
  return urlPattern.test(str);
}

export type MarginToCssProp = {
  t?: number
  r?: number
  b?: number
  l?: number
  v?: number
  h?: number
}

export type PaddingToCssProp = MarginToCssProp

export const marginToCssProp = (value: MarginToCssProp): string | null => value
  ? `${rem(value.t) || rem(value.v) || '0px'} ` +
    `${rem(value.r) || rem(value.h) || '0px'} ` +
    `${rem(value.b) || rem(value.v) || '0px'} ` +
    `${rem(value.l) || rem(value.h) || '0px'}`
  : null


export const paddingToCssProp = (value: PaddingToCssProp): string | null => marginToCssProp(value)

// TODO: remove when rem is properly implemented in re-design as a whole (when not breaking legacy project)
/**
 * @description Does what the scss rem function does but in javascript
 */
export const rem = (value: FixMeLater): string | 0 => value ? `${value.toString().split('px')[0] / 16}rem` : 0


export const validatePhoneNumber = (str: string): boolean => /^((((0{2}?)|(\+){1})46)|0)\d{5,15}$/g.test(str)

export const isPhoneNumber = (str: string): boolean => /^[+0-9 -]*$/g.test(str)

export const validateMobileNumber = (str: string): boolean => /^((((0{2}?)|(\+){1})46)|0)7[- ]*(\d[- ]*){8}$/.test(str)

export const cleanPhoneNumberString = (str: string): string => str.replace(/[^0-9+]/g, '')

export const validateZipCode = (str: string): boolean => /^\d{3}\s?\d{2}$/.test(str)

export const validateEmail = (str: string): boolean => {
  const result = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
  
  if (result) {
    const domain = str.slice(str.indexOf('@') + 1, str.lastIndexOf('.'))
    return /^(\w)?.*\D.*(\w)?$/g.test(domain)
  }
  else return result
}


export const getCareUnitIconName = (careAreas: string[]): CareUnitIcons => {
  const iconMap = {
    VÅRDCENTRAL: 'stethoscope',
    PSYKIATRI: 'psychiatric-care',
    REHAB: 'dumbbell',
    GYNEKOLOGI: 'gynecology',
    HUD: 'skin',
    KIRURGI: 'surgeon',
    ORTOPEDI: 'orthopedics',
    RÖNTGEN: 'x-ray',
    UROLOGI: 'urology',
    ÖGON: 'eye',
    'ÖRON, NÄSA, HALS': 'ears-nose-throat',
    REUMATOLOGI: 'rheumatology',
    ULTRALJUD: 'ultra-sound',
    UNGDOMSMOTTAGNING: 'youth-centre',
    JOURMOTTAGNING: 'on-call-clinic',
    BARNMORSKEMOTTAGNING: 'mid-wife',
    // FLYGMEDICIN: '',
    FÖRETAGSHÄLSA: 'corporate-care',
    BARNAVÅRDSCENTRAL: 'nursing-center',
    KÖNSSJUKDOMAR: 'close',
  }

  if (careAreas.length > 1 || !careAreas[0]) return 'hospital-house'
  
  const careArea = careAreas[0].toUpperCase()
  if (careArea in iconMap) return iconMap[careArea]
  else return 'hospital-house'
}


export const capitalizeWords = (str: string): string => {
  if (!str.includes(' ')) return str[0].toUpperCase() + str.slice(1, str.length)

  const words = str.split(' ')
  let capitalizedWords = ''

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    capitalizedWords += word[0].toUpperCase() + word.slice(1, word.length).toLowerCase() + ' ' // capitalizes first letter, concats the rest and adds white space at the end
  }

  return capitalizedWords.slice(0, -1) // slice removes the last space
}


export const getNumOfChildren = (children: FixMeLater): number => {
  if (!children) return 0

  // Children gets passed differently depending on the type of components.
  // This is if it's Button components
  else if(children.props) {
    const _children = children.props.children

    if (typeof _children === 'string' || _children instanceof String) return 1
    else if (!_children) return 1
    else return _children.length
  }

  // Else they get passed in an array
  else if (children instanceof Array) {
    // If children are passed with conditional rendering, they still get counted even if they're not rendered
    let childCount = 0
    
    for (let i = 0; i < children.length; i++) {
      if (children[i]) childCount++
    }

    return childCount
  }
  // If it's not an array, it's only 1 component
  else return 1
}

export const getButtonIconColors = (variant: ButtonVariants | IconButtonVariants): IconColors[] | null[] => {
  // [defaultColor, hoverColor, disabledColor]
  if (variant === 'primary' || variant === 'danger') return ['secondary', 'secondary', 'secondary']
  else if (variant === 'secondary') return ['primary', 'hover', 'secondary']
  else if (variant.startsWith('secondary-')) return ['primary', 'hover', 'disabled']
  else if (variant === 'text') return ['primary', 'secondary', 'disabled']
  
  else return [null, null, null]
}


export const isError = (value: any | AxiosError): value is AxiosError => value && value.isAxiosError === true

export const isAxiosError = (value: any | AxiosError): value is AxiosError => value && value.isAxiosError === true


export const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms))


export const formatPhoneToE164 = (phoneNumber: string) => {
  const result = /^\+\d\d/.exec(phoneNumber);
  const countryCode: string | null = result && result[0];

  let subscriberNumber: string = phoneNumber

  if (countryCode) {
    subscriberNumber = phoneNumber.slice(3, phoneNumber.length)
  }

  if (subscriberNumber[0] === "0") {      
    subscriberNumber = subscriberNumber.slice(1, subscriberNumber.length)
  }

  return {
    countryCode: countryCode ?? "+46",
    subscriberNumber
  }
}
