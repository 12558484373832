import { isValidPhoneNumber } from 'react-phone-number-input/mobile'

export const isValidMobilePhoneNumber = {
  rule: (val: string) => {
    return isValidPhoneNumber(val);
  },
  errorMessage: "Detta fält är obligatoriskt"
};


export {
  useDeviceSize,
  useDisableScroll
} from './hooks'

export {
  isWebUrl,
  marginToCssProp,
  paddingToCssProp,
  rem,
  validatePhoneNumber,
  isPhoneNumber,
  validateMobileNumber,
  cleanPhoneNumberString,
  validateZipCode,
  validateEmail,
  getCareUnitIconName,
  capitalizeWords,
  getNumOfChildren,
  getButtonIconColors,
  isAxiosError,
  sleep,
  formatPhoneToE164,
  isError
} from './functions'

export type {
  MarginToCssProp,
  PaddingToCssProp
} from "./functions"
