import { featureConfigOfSession } from "./sessionUtils";
import type { FeatureConfig } from "src/api/services/FeaturesApi"

export const defaultFeatureConfig = {
    findHealthcareUnits: true,
    smsValidation: false,
    eid2Enabled: true,
    useService2: false
}

const initialState = {
    ...defaultFeatureConfig,
    reloadIntervalId: null,
};

export const SET_FEATURE_CONFIG = "SET_FEATURE_CONFIG";
export const SET_CONFIG_RELOAD_INTERVAL_ID = "SET_CONFIG_RELOAD_INTERVAL_ID";

export const actions = {
    setFeatureConfig: (config: FeatureConfig) => ({
        type: SET_FEATURE_CONFIG,
        payload: config,
    }),
    setReloadIntervalId: (intervalId: number) => ({
        type: SET_CONFIG_RELOAD_INTERVAL_ID,
        payload: intervalId,
    })
}


type FeatureActions =
    { type: typeof SET_FEATURE_CONFIG, payload: FeatureConfig }
    | { type: typeof SET_CONFIG_RELOAD_INTERVAL_ID, payload: number }

export const reducer = (state = initialState, { type, payload }: FeatureActions) => {
    if (type === SET_FEATURE_CONFIG) {
        featureConfigOfSession.set(payload || defaultFeatureConfig);
        return {
            ...state,
            ...(payload || defaultFeatureConfig),
        }
    }
    else if (type == SET_CONFIG_RELOAD_INTERVAL_ID) {
        return {
            ...state,
            reloadIntervalId: payload,
        }
    }
    else {
        return state;
    }
}
